<template>
  <v-container
    id="cliente-profile"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Modificar perfil
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Complete su perfil
            </div>
          </template>
          <v-form
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.empresa"
                  label="Notaría"
                  disabled
                />
              </v-col>

              <v-col
                cols="12"
                md="8"
              >
                <v-text-field
                  v-model="editedItem.email"
                  :rules="emailRules"
                  label="Correo electrónico"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="info"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                      <span>El cambio de correo electrónico requiere un nuevo inicio de sesión</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="editedItem.nombre"
                  :rules="[v => !!v || 'El nombre es requerido']"
                  label="Nombre"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="editedItem.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  label="Nueva contraseña"
                  hint="Ingresa al menos 8 caracteres"
                  counter
                  @click:append="show1 = !show1"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="editedItem.confirmation"
                  :rules="rules"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  label="Confirmar contraseña"
                  hint="Ingresa al menos 8 caracteres"
                  counter
                  @click:append="show2 = !show2"
                />
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  color="indigo"
                  class="mr-0"
                  @click="save(editedItem.id)"
                >
                  Modificar perfil
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UsuariosApi from '@/services/api/Usuarios'

  export default {
    name: 'ClienteProfile',

    data: vm => {
      return {
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        valid: true,
        lazy: false,
        show1: false,
        show2: false,
        allowSpaces: false,
        editedItem: {
          id: '',
          nombre: '',
          email: '',
          password: '',
          confirmation: '',
          empresa: '',
        },
        emailRules: [
          v => !!v || 'El correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Debes ingresar un correo electrónico válido',
        ],
      }
    },
    computed: {
      rules () {
        const rules = []

        if (!this.allowSpaces) {
          const rule =
            v => (v || '').indexOf(' ') < 0 ||
              'No se permiten espacios en la contraseña'

          rules.push(rule)
        }

        if (this.editedItem.password) {
          const rule =
            v => (!!v && v) === this.editedItem.password ||
              'Las contraseñas no coinciden'

          rules.push(rule)
        }

        return rules
      },
    },

    created () {
      this.fetchProfile()
    },

    methods: {
      fetchProfile () {
        UsuariosApi.getUsuarioByEmail(localStorage.getItem('user'))
          .then(usuario => {
            this.editedItem = Object.assign({}, usuario)
          })
          .catch(error => console.log(error))
      },
      save (id) {
        if (this.$refs.form.validate()) {
          UsuariosApi.updateUsuario(id, this.editedItem)
            .then(response => {
              this.text = 'Registro modificado correctamente'
              this.snackbar = true
              this.colorSnak = 'success'
            })
            .catch(error => {
              console.log(error)
              this.text = 'El registro no puede ser modificado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },
    },
  }
</script>
